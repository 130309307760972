import { navigate } from 'gatsby';
import { useEffect } from 'react';
import useAuthStatus from './auth-status.hook';

export default function useAntiAuthGuard(navigateTo: string) {
  const { isAuthenticated, hasLoadedAuth } = useAuthStatus();

  useEffect(() => {
    let isMounted = true;

    if(hasLoadedAuth) {
      if(isAuthenticated) {
        if(isMounted) {
          navigate(navigateTo, { replace: true });
        }
      }
    }

    return () => { isMounted = false; };
  }, [isAuthenticated, hasLoadedAuth]);
}