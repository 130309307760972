import { useForm } from '@/src/hooks/form.hook';
import { createFormField, FormConfig, requiredValidator } from '@/src/models/form.model';
import { Link, navigate, PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { TranslationService } from '../../../services/translation.service';
import { TranslationProvider } from '@/src/contexts/translation.context';
import { Helmet } from 'react-helmet';
import Help from '@/assets/icons/help.inline.svg';

import { FormError } from '@/src/components/form-error/form-error';
import Header from '@/src/components/header/header';
import { LargeButton } from '@/src/components/button/button';
import * as styles from './login.module.scss';
import useAntiAuthGuard from '@/src/hooks/anti-auth-guard.hook';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import Spinner from '@/src/components/spinner/spinner';
import { useApi } from '@/src/contexts/api.context';

const loginForm: FormConfig = {
  username: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="username"
            name="username"
            type="text"
            autoComplete="off"
            placeholder={translation.translate('authentication.username')}
            onChange={onInputChange}
            value={value} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  password: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="password"
            name="password"
            type="password"
            autoComplete="off"
            placeholder={translation.translate('authentication.password')}
            onChange={onInputChange}
            value={value} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  }
};

export default function LoginPage({ pageContext }: PageProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageContext));
  useAntiAuthGuard(translation.appLinkPrefix);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const { renderInput, touchForm, isFormValid, form } = useForm(translation, loginForm);
  const { hasLoadedAuth, isAuthenticated } = useAuthStatus();
  const [loginReady, setLoginReady] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if(hasLoadedAuth) {
      if(!isAuthenticated) {
        if(isMounted) {
          setLoginReady(true);
        }
      }
    }

    return () => { isMounted = false; };
  }, [hasLoadedAuth]);

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormErrorMessage(null);
    setLoggingIn(true);
    touchForm();

    if(!isFormValid()) {
      setLoggingIn(false);
      return;
    }

    try {
      await apiService.login(form.username.value, form.password.value);
      await navigate(`${translation.appLinkPrefix}`, { replace: true });
    } catch(err) {
      setFormErrorMessage(translation.translate('authentication.login_failed'));
      setLoggingIn(false);
    }
  };

  return (
    <>
      <TranslationProvider value={translation}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{translation.translate('register.title')}</title>
          <meta name="description" content={translation.translate('register.description')} />
          <meta http-equiv="content-language" content={translation.lang} />
          <html className="unauthenticated"></html>
        </Helmet>

        <Header unauthenticated={true} pageContext={pageContext}></Header>

        {
          !loginReady ? <Spinner dark={true} /> :
            <>
              <div className="unauthenticated__wrapper">
                <div className="unauthenticated__inner">
                  <form className="form" onSubmit={login}>
                    <h1>{translation.translate('authentication.log_in')}</h1>

                    <p className="auth__description">{translation.translate('authentication.login_description')}</p>

                    <div className="form__group">
                      <div className="form__field">
                        {renderInput('username')}
                      </div>
                    </div>

                    <div className="form__group">
                      <div className="form__field">
                        {renderInput('password')}
                      </div>
                    </div>

                    {formErrorMessage ?
                      <div className="form__error-message" dangerouslySetInnerHTML={{ __html: formErrorMessage }}></div> : null}

                    <div className="form__group form__group--submit">
                      <LargeButton type="submit" loading={loggingIn}>
                        <span>{translation.translate('authentication.log_in')}</span>
                      </LargeButton>
                    </div>
                  </form>

                  <div className="form__group auth-link">
                    <Link to={`${translation.linkPrefix}/register`}>{translation.translate('authentication.create_new_account')}</Link>
                  </div>

                  <Link className={styles.forgotPasswordLink} to={`${translation.linkPrefix}/forgot-password`}>
                    <span className={styles.forgotPassword__inner}>
                      <Help className={styles.helpIcon}></Help>&nbsp; {translation.translate('authentication.forgot_password')}
                    </span>
                  </Link>
                </div>
              </div>

              <div className="decoration-circle-wrapper">
                <div className="decoration-circle">
                </div>
              </div>


            </>
        }
      </TranslationProvider>
    </>
  );
}